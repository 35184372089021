import React from 'react'


const Footer = () => {
  return (
    <div className='footer'>

      <div className="copyright">
        <p><a href="https://aptale-designs.github.io/Aparna-Chauhan/">Aptale Designs</a>  © {new Date().getFullYear()} All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer
