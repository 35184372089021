import React, { useState } from 'react'
import logo from "../Images/logo.png"

const Header = () => {

    const links = [
        {
            title: "Home",
            url: "/"
        },
        {
            title: "About",
            url: "#about"
        },
        {
            title: "Services",
            url: "#services"
        },
        {
            title: "Team",
            url: "#team"
        },
        {
            title: "Contact",
            url: "#contact"
        },
        {
            title: "Begin Your Design Story",
            url: "#begin-your-design-story"
        },
    ]

    const [mobileNav, setMobileNav] = useState("none");

    const open = () => {
        setMobileNav("flex");
    }

    const close = () => {
        setMobileNav("none");
    }

    const hide = () => {
        setMobileNav("none");
    }


    return (
        <div className='header'>
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
            <div className="header-links">
                {links.map((item) => (
                    <a href={item.url}>{item.title}</a>
                ))}
            </div>
            <svg onClick={open} className='menu' id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m464.883 64.267h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m47.134 64.267c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 208.867c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 353.467c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m464.883 208.867h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m464.883 353.467h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.012-21.136-47.149-47.116-47.149z" /></svg>

            <div style={{ display: mobileNav }} className="header-mobile">
                <div className="header-mobile-links">
                    {links.map((item) => (
                        <a onClick={hide} href={item.url}>{item.title}</a>
                    ))}
                </div>
                <svg onClick={close} className='close' id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>
            </div>

        </div>


    )
}

export default Header
