import React, { useEffect, useState, useRef } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import img from "../Images/2.jpg"
import ceo from "../Images/47752.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import UAE from "../Images/uae.png"
import India from "../Images/india.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player/youtube'

import publicart from "../Images/Services/Public Art.webp"
import interiorart from "../Images/Services/Interior Art.webp"
import fountainsservice from "../Images/Services/Fountains.webp"

import team1 from "../Images/Team/2d Designers.jpg"
import team2 from "../Images/Team/3D Designers.jpg"
import team3 from "../Images/Team/Artists.jpg"

import p1 from "../Images/Public Art Installation/1.webp"
import p2 from "../Images/Public Art Installation/2.webp"
import p3 from "../Images/Public Art Installation/3.webp"
import p4 from "../Images/Public Art Installation/4.webp"
import p5 from "../Images/Public Art Installation/5.webp"
import p6 from "../Images/Public Art Installation/6.webp"
import p7 from "../Images/Public Art Installation/7.webp"
import p8 from "../Images/Public Art Installation/8.webp"
import p9 from "../Images/Public Art Installation/9.webp"
import p10 from "../Images/Public Art Installation/10.webp"
import p11 from "../Images/Public Art Installation/11.webp"
import p12 from "../Images/Public Art Installation/12.webp"
import p13 from "../Images/Public Art Installation/13.webp"
import p14 from "../Images/Public Art Installation/14.webp"
import p15 from "../Images/Public Art Installation/15.webp"
import p16 from "../Images/Public Art Installation/16.webp"
import p17 from "../Images/Public Art Installation/17.webp"
import p18 from "../Images/Public Art Installation/18.webp"
import p19 from "../Images/Public Art Installation/19.webp"
import p20 from "../Images/Public Art Installation/20.webp"
import p21 from "../Images/Public Art Installation/21.webp"
import p22 from "../Images/Public Art Installation/22.webp"
import r1 from "../Images/Residential/1.webp"
import r2 from "../Images/Residential/2.webp"
import r3 from "../Images/Residential/3.webp"
import r4 from "../Images/Residential/4.webp"
import r5 from "../Images/Residential/5.webp"
import r6 from "../Images/Residential/6.webp"
import r7 from "../Images/Residential/7.webp"
import r8 from "../Images/Residential/8.webp"
import r9 from "../Images/Residential/9.webp"
import r10 from "../Images/Residential/10.webp"
import r11 from "../Images/Residential/11.webp"
import r12 from "../Images/Residential/12.webp"
import r13 from "../Images/Residential/13.webp"
import r14 from "../Images/Residential/14.webp"
import r15 from "../Images/Residential/15.webp"
import r16 from "../Images/Residential/16.webp"
import r17 from "../Images/Residential/17.webp"
import r18 from "../Images/Residential/18.webp"
import r19 from "../Images/Residential/19.webp"
import r20 from "../Images/Residential/20.webp"
import r21 from "../Images/Residential/21.webp"
import r22 from "../Images/Residential/22.webp"
import f1 from "../Images/Fountains/1.webp"
import f2 from "../Images/Fountains/2.webp"
import f3 from "../Images/Fountains/3.webp"
import f4 from "../Images/Fountains/4.webp"
import f5 from "../Images/Fountains/5.webp"
import f6 from "../Images/Fountains/6.webp"
import f7 from "../Images/Fountains/7.webp"
import f8 from "../Images/Fountains/8.webp"

const Home = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const services = [
    {
      img: interiorart,
      title: "Interior Art ",
      description: "Infusing homes and office spaces with bespoke, artistic installations that exude luxury and sophistication.",
      id: "Residential"
    },
    {
      img: publicart,
      title: "Public Art ",
      description: "Enriching urban landscapes with opulent art installations that inspire awe.",
      id: "Public"
    },
    {
      img: fountainsservice,
      title: "Fountains & Pool Statues",
      description: "Elevating outdoor spaces with majestic fountain designs that evoke tranquility and grace.",
      id: "Fountains"
    },
  ]

  const [zoom, setZoom] = useState({
    display: "none",
    src: ""
  })

  const [show, setShow] = useState({
    displayPublic: false,
    displayResidential: false,
    displayFountains: false,
  })

  const zoomin = (e) => {
    let id = e.target.src;
    setZoom({
      display: "",
      src: id
    })
  }

  const close = () => {
    setZoom({
      display: "none",
    })
  }

  const showPublic = (e) => {

    let showid = e.target.id;

    if (showid == "Public") {
      setShow({
        displayPublic: true,
        displayFountains: false,
        displayResidential: false
      })
    } else if (showid == "Residential") {
      setShow({
        displayPublic: false,
        displayFountains: false,
        displayResidential: true
      })
    } else if (showid == "Fountains") {
      setShow({
        displayPublic: false,
        displayFountains: true,
        displayResidential: false
      })
    } else {
      setShow({
        displayPublic: false,
        displayFountains: false,
        displayResidential: false
      })
    }

  }


  // contact form 

  const form = useRef();
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Subject: "",
    Message: ""
  });

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  }

  //connect with firebase
  const SubmitData = async (e) => {
    e.preventDefault();
    const { Name, Email, Phone, Subject, Message } = userData;
    if (Name == "" || Email == "" || Phone == "" || Subject == "" || Message == "") {
      toast.warning("Please Enter Data");
    }
    else {
      emailjs.sendForm('service_agvm8di', 'template_7hfainz', form.current, 'PT4cFKqMsSX0Fqbs2')
        .then((result) => {
          console.log(result.text);

        }, (error) => {
          console.log(error.text);
        });

      toast.success("Your request has been submitted, Our Art Consultancy team will connect you shortly. Thank You")
      setUserData({
        Name: "",
        Email: "",
        Phone: "",
        Subject: "",
        Message: ""
      })
    }







  };

  return (
    <div>
      <ToastContainer />
      <Header />
      <div className="about">
        <div className="about-text">
          <p>At Sketchostory, we are storytellers of spaces, weaving tales of natural beauty through our innovative landscaping creations. Our journey began with a passion for transforming ordinary landscapes into immersive experiences. We've spent years perfecting our craft, always seeking to transcend the boundaries of traditional landscaping.</p>
          <a href="#begin-your-design-story">Begin your design story</a>
        </div>
        <div className="about-img">
          <img src={img} alt="about" />
        </div>
      </div>

      <div id='about' className="crafting">
        <div className="title">
          <h3>Crafting Luxury Designs for a Timeless Future</h3>
        </div>
        <div className="crafting-body">
          <AnimationOnScroll animateIn="animate__slideInUp">
            <div className="video">

              <ReactPlayer
                style={{ marginBottom: "2%", border: "2px solid #B49479" }}
                width={"100%"}
                height={"70vh"}
                url='https://www.youtube.com/watch?v=vqxMt355jiA' />


            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInUp">
            <div className="video">
              {/* <img src={img1} alt="video" /> */}
              <ReactPlayer
                style={{ marginBottom: "2%", border: "2px solid #B49479" }}
                width={"100%"}
                height={"70vh"}
                url='https://www.youtube.com/watch?v=rGnO0cPHRMY' />

              {/* <div className="play">
                <img src={play} alt="play button" />
              </div> */}
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInUp">
            <div className="video">
              {/* <img src={img1} alt="video" /> */}
              <ReactPlayer
                style={{ marginBottom: "2%", border: "2px solid #B49479" }}
                width={"100%"}
                height={"70vh"}
                url='https://www.youtube.com/watch?v=JBTZEOkhN-Q' />

              {/* <div className="play">
                <img src={play} alt="play button" />
              </div> */}
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInUp">
            <div className="video">
              {/* <img src={img1} alt="video" /> */}
              <ReactPlayer
                style={{ marginBottom: "2%", border: "2px solid #B49479" }}
                width={"100%"}
                height={"70vh"}
                url='https://www.youtube.com/watch?v=gRB644T8yV4' />

              {/* <div className="play">
                <img src={play} alt="play button" />
              </div> */}
            </div>
          </AnimationOnScroll>
        </div>
      </div>

      <div id='services' className="services">
        <div className="title">
          <h3>What We Do</h3>
        </div>
        <div className="services-body">
          {services.map((item) => (
            <div className="services-card">
              <AnimationOnScroll animateIn="animate__slideInUp">
                <img src={item.img} alt="service1" />
                <div className="service-card-text">
                  <h6>{item.title}</h6>
                  <p>{item.description}</p>
                  <a href={"#" + item.id} id={item.id} onClick={showPublic}>Learn More</a>
                </div>
              </AnimationOnScroll>
            </div>
          ))}
        </div>

        <div className="zoom" style={{ display: zoom.display }}>
          <svg onClick={close} className='close' height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" /><path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0" /><path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0" /></svg>
          <img src={zoom.src} alt="" />
        </div>

        {show.displayPublic && (
          <div id='Public' className="ser">
            <h3>public art</h3>
            <div className="ser-body" >
              <img src={p1} alt="" onClick={zoomin} />
              <img src={p2} alt="" onClick={zoomin} />
              <img src={p3} alt="" onClick={zoomin} />
              <img src={p4} alt="" onClick={zoomin} />
              <img src={p5} alt="" onClick={zoomin} />
              <img src={p6} alt="" onClick={zoomin} />
              <img src={p7} alt="" onClick={zoomin} />
              <img src={p8} alt="" onClick={zoomin} />
              <img src={p9} alt="" onClick={zoomin} />
              <img src={p10} alt="" onClick={zoomin} />
              <img src={p11} alt="" onClick={zoomin} />
              <img src={p12} alt="" onClick={zoomin} />
              <img src={p13} alt="" onClick={zoomin} />
              <img src={p14} alt="" onClick={zoomin} />
              <img src={p15} alt="" onClick={zoomin} />
              <img src={p16} alt="" onClick={zoomin} />
              <img src={p17} alt="" onClick={zoomin} />
              <img src={p18} alt="" onClick={zoomin} />
              <img src={p19} alt="" onClick={zoomin} />
              <img src={p20} alt="" onClick={zoomin} />
              <img src={p21} alt="" onClick={zoomin} />
              <img src={p22} alt="" onClick={zoomin} />

            </div>

          </div>
        )}

        {show.displayResidential && (
          <div id='Residential' className="ser">
            <h3>Interior Art </h3>
            <div className="ser-body" >
              <img src={r1} alt="" onClick={zoomin} />
              <img src={r2} alt="" onClick={zoomin} />
              <img src={r3} alt="" onClick={zoomin} />
              <img src={r4} alt="" onClick={zoomin} />
              <img src={r5} alt="" onClick={zoomin} />
              <img src={r6} alt="" onClick={zoomin} />
              <img src={r7} alt="" onClick={zoomin} />
              <img src={r8} alt="" onClick={zoomin} />
              <img src={r9} alt="" onClick={zoomin} />
              <img src={r10} alt="" onClick={zoomin} />
              <img src={r11} alt="" onClick={zoomin} />
              <img src={r12} alt="" onClick={zoomin} />
              <img src={r13} alt="" onClick={zoomin} />
              <img src={r14} alt="" onClick={zoomin} />
              <img src={r15} alt="" onClick={zoomin} />
              <img src={r16} alt="" onClick={zoomin} />
              <img src={r17} alt="" onClick={zoomin} />
              <img src={r18} alt="" onClick={zoomin} />
              <img src={r19} alt="" onClick={zoomin} />
              <img src={r20} alt="" onClick={zoomin} />
              <img src={r21} alt="" onClick={zoomin} />
              <img src={r22} alt="" onClick={zoomin} />

            </div>

          </div>
        )}

        {show.displayFountains && (
          <div id='Fountains' className="ser">
            <h3>Fountains & Pool Statues</h3>
            <div className="ser-body" >
              <img src={f1} alt="" onClick={zoomin} />
              <img src={f2} alt="" onClick={zoomin} />
              <img src={f3} alt="" onClick={zoomin} />
              <img src={f4} alt="" onClick={zoomin} />
              <img src={f5} alt="" onClick={zoomin} />
              <img src={f6} alt="" onClick={zoomin} />
              <img src={f7} alt="" onClick={zoomin} />
              <img src={f8} alt="" onClick={zoomin} />

            </div>

          </div>
        )}

      </div>

      <div id='team' className="team">
        <div className="title">
          <h3>Team</h3>
        </div>

        <div className="team-body">
          <div className="team-header">
            <div className="team-header-text">
              <AnimationOnScroll animateIn="animate__slideInLeft">

                <h4>RAJ SHEKHAR </h4>
                <i>Founder and Ceo</i>
                <p>Embarking on his artistic journey at a very young age Raj swiftly transitioned to design. With over a decade of diverse experiences he now aspires to lead globally, fostering inclusivity across cultures and industries.    </p>
                <p>From India to the MENA region, Raj has gained a pioneering spirit from their innovation-driven environments.
                  These experiences have empowered him to overcome challenges, take calculated risks, and lead a company devoted to diversity, innovation, creativity, and excellence.</p>

              </AnimationOnScroll>
            </div>

            <div className="team-header-img">
              <AnimationOnScroll animateIn="animate__slideInRight">
                <img src={ceo} alt="CEO" />
              </AnimationOnScroll>

            </div>
          </div>
          <h5 className='meet'>meet our experts</h5>
          <AnimationOnScroll animateIn="animate__fadeInUpBig">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={4000} swipeable={false} showIndicators={false}>
              <div className="team-slider">
                <div className="team-slider-text">
                  <h4>2D Designers</h4>
                  <p>Meet our skilled 2D design team, blending creativity, expertise, and passion. With sharp attention to detail and expertise in visual storytelling, we craft captivating visuals across illustration, graphic design, and digital art.</p>
                </div>
                <div className="team-slider-img">
                  <img src={team1} alt="team" />
                </div>
              </div>
              <div className="team-slider">
                <div className="team-slider-text">
                  <h4>3D Designers</h4>
                  <p>Our visionary 3D design team, where boundless creativity reigns supreme. Our skilled individuals are driven by a fervent passion for pushing the boundaries of three-dimensional visualization. Specializing in architectural renderings to product prototypes, we meticulously craft immersive experiences that captivate and
                    inspire.</p>
                </div>
                <div className="team-slider-img">
                  <img src={team2} alt="team" />
                </div>
              </div>
              <div className="team-slider">
                <div className="team-slider-text">
                  <h4>Artists</h4>
                  <p>Explore the faces behind our designs and uncover the creative brilliance driving our success. Collaborative and enthusiastic, we push creative boundaries to deliver exceptional, tailored solutions.</p>
                </div>
                <div className="team-slider-img">
                  <img src={team3} alt="team" />
                </div>
              </div>
            </Carousel>
          </AnimationOnScroll>
        </div>
      </div>

      <div id="begin-your-design-story" className="story">
        <div className="title">
          <h3>Ready to redefine luxury with Sketchostory?</h3>
          <p> Share your vision with us, and let's craft a design narrative that resonates with your unique style.</p>
        </div>
        <AnimationOnScroll animateIn="animate__slideInUp">
          <form ref={form}>

            <input type="text" name="Name" id="Name" placeholder='Name' value={userData.Name}
              onChange={postUserData}
              required />

            <input type="email" name="Email" id="Email" placeholder='Email' value={userData.Email}
              onChange={postUserData}
              required />
            <input type="text" name="Phone" id="Phone" placeholder='Phone' value={userData.Phone}
              onChange={postUserData}
              required />
            <input type="text" name="Subject" id="Subject" placeholder='Subject' value={userData.Subject}
              onChange={postUserData}
              required />

            <textarea name="Message" id="Message" cols="30" rows="10" placeholder='Message' value={userData.Message}
              onChange={postUserData}
              required>
            </textarea>
            <button onClick={SubmitData}>Submit</button>
          </form>
        </AnimationOnScroll>
      </div>

      <div id='contact' className="contact">
        <div className="title">
          <h3>Contact</h3>
        </div>

        <div className="contact-body">
          <AnimationOnScroll animateIn="animate__slideInUp">
            <div className="heading">
              <img src={India} alt="India" />
              <h4>India</h4>
            </div>

            <div className="contact-card">
              <p><span>Phone:</span><span><a href="tel:918150071248">+91 81500 71248</a></span></p>
              <p><span>Email:</span><span><a href="mailto:contact@sketchostory.com">contact@sketchostory.com</a></span></p>
              <p><span>Office Address:</span><span>E72
                Vishwapriya Nagar, Bommanhalli, Begur, Bangalore Karnataka, India  560060</span></p>
              <p><span>Warehouse Address:</span><span>384 Chhend, Kalinga-vihar, Rourkela, odisha, India 769015</span></p>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInUp">
            <div className="heading heading2">
              <img src={UAE} alt="UAE" />
              <h4>UAE</h4>
            </div>

            <div className="contact-card">
              <p><span>Phone:</span><span><a href="tel:971504348145">+971-504348145</a></span></p>
              <p><span>Email:</span><span><a href="mailto:contact@sketchostory.com">contact@sketchostory.com</a></span></p>
              <p><span>Office Address:</span><span>1601, FAS Building, Al Soor, Sharjah</span></p>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
