import "./Components/Sass/main.scss"
import "./Components/Sass/media.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Components/Pages/Home"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />     
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
